<template>
  <Dialog
    v-model:visible="showModal"
    :modal="true"
    :header="modalHeader"
    :dismissable-mask="true"
    :close-on-escape="true"
  >
    <div :style="{ width: '50vw' }">
      <div class="card card-w-title">
        <div class="p-grid form-group">
          <div>
            <div v-if="form.id != null" class="p-field mb-4">
              <label
                for="campaignNameInput"
                class="block text-900 font-medium mb-2"
                >Id</label
              >
              <InputText
                type="text"
                id="campaignNameInput"
                class="p-inputtext p-component w-full p-3"
                :class="
                  validationErrors && validationErrors.id ? 'p-invalid' : ''
                "
                :disabled="true"
                v-model="form.id"
              />
              <small
                class="p-error"
                v-if="validationErrors && validationErrors.id"
              >
                {{ validationErrors.name[0] }}
              </small>
            </div>

            <div class="p-field mb-4">
              <label
                for="campaignNameInput"
                class="block text-900 font-medium mb-2"
                >Campaign Id</label
              >
              <InputText
                type="text"
                id="campaignNameInput"
                class="p-inputtext p-component w-full p-3"
                :class="
                  validationErrors && validationErrors.campaign_id
                    ? 'p-invalid'
                    : ''
                "
                :disabled="true"
                v-model="form.campaign_id"
              />
              <small
                class="p-error"
                v-if="validationErrors && validationErrors.campaign_id"
              >
                {{ validationErrors.campaign_id[0] }}
              </small>
            </div>

            <div class="p-field mb-3">
              <label
                for="mailingIdInput"
                class="block text-900 font-medium mb-2"
                >Mailing Id</label
              >
              <InputText
                type="text"
                id="mailingIdInput"
                class="p-inputtext p-component w-full p-3"
                :class="
                  validationErrors && validationErrors.description
                    ? 'p-invalid'
                    : ''
                "
                v-model="form.mailing_id"
                v-on:keyup.enter="console.log()"
              />
              <small
                class="p-error"
                v-if="validationErrors && validationErrors.description"
              >
                {{ validationErrors.description[0] }}
              </small>
            </div>
            <div class="p-field mb-3">
              <label
                for="phoneNumberInput"
                class="block text-900 font-medium mb-2"
                >Phone #</label
              >
              <InputText
                type="text"
                id="phoneNumberInput"
                class="p-inputtext p-component w-full p-3"
                :class="
                  validationErrors && validationErrors.phone_number
                    ? 'p-invalid'
                    : ''
                "
                v-model="form.phone_number"
                v-on:keyup.enter="console.log()"
              />
              <small
                class="p-error"
                v-if="validationErrors && validationErrors.phone_number"
              >
                {{ validationErrors.phone_number[0] }}
              </small>
            </div>
            <div class="p-field mb-3">
              <label for="labelInput" class="block text-900 font-medium mb-2"
                >Label</label
              >
              <InputText
                type="text"
                id="labelInput"
                class="p-inputtext p-component w-full p-3"
                :class="
                  validationErrors && validationErrors.label ? 'p-invalid' : ''
                "
                v-model="form.label"
                v-on:keyup.enter="console.log()"
              />
              <small
                class="p-error"
                v-if="validationErrors && validationErrors.label"
              >
                {{ validationErrors.label[0] }}
              </small>
            </div>
            <div class="p-field mb-3">
              <label
                for="campaignCycleIdInput"
                class="block text-900 font-medium mb-2"
                >Campaign Cycle Id</label
              >
              <InputText
                type="text"
                id="campaignCycleIdInput"
                class="p-inputtext p-component w-full p-3"
                :class="
                  validationErrors && validationErrors.campaign_cycle_id
                    ? 'p-invalid'
                    : ''
                "
                v-model="form.campaign_cycle_id"
                v-on:keyup.enter="console.log()"
              />
              <small
                class="p-error"
                v-if="validationErrors && validationErrors.campaign_cycle_id"
              >
                {{ validationErrors.campaign_cycle_id[0] }}
              </small>
            </div>

            <div class="text-center">
              <Button
                v-if="form.id != null"
                label="UPDATE"
                class="p-button p-component p-3"
                :loading="loading"
                @click="saveChanges"
              ></Button>
              <Button
                v-if="form.id == null"
                label="CREATE"
                class="p-button p-component p-3"
                :loading="loading"
                @click="saveChanges"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import EventBus from "@/EventBus";
import InboundPhoneNumber from "@/models/InboundPhoneNumber";
import { useAppStore } from "@/stores/app";

export default {
  name: "EditInboundPhoneNumber",
  props: {
    inboundPhoneNumber: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["updated"],
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        mailing_date: "",
      },
      validationErrors: [],
      loading: false,

      showModal: false,
    };
  },
  computed: {
    modalHeader() {
      return this.form.id != null
        ? "Edit Inbound Phone Numbers"
        : "Create New Inbound Phone Number";
    },
  },
  watch: {
    inboundPhoneNumber() {
      this.form.campaign_id = this.appStore.currentCampaign.id;
      this.form.id = this.inboundPhoneNumber.id;
      this.form.mailing_id = this.inboundPhoneNumber.mailing_id;
      this.form.phone_number = this.inboundPhoneNumber.phone_number;
      this.form.label = this.inboundPhoneNumber.label;
      this.form.campaign_cycle_id = this.inboundPhoneNumber.campaign_cycle_id;
    },
  },
  mounted() {
    EventBus.on("toggle-client-modal", () => {
      this.showModal = !this.showModal;
    });
  },
  methods: {
    saveChanges() {
      this.loading = true;

      let inboundPhoneNumber = new InboundPhoneNumber({});

      inboundPhoneNumber.id = this.inboundPhoneNumber.id;
      inboundPhoneNumber.campaign_id = this.form.campaign_id;
      inboundPhoneNumber.mailing_id = this.form.mailing_id;
      inboundPhoneNumber.phone_number = this.form.phone_number;
      inboundPhoneNumber.label = this.form.label;
      inboundPhoneNumber.campaign_cycle_id = this.form.campaign_cycle_id;

      console.log(
        "EditInboundPhoneNumber->saveChanges with: inboundPhoneNumber = "
          .inboundPhoneNumber
      );

      inboundPhoneNumber
        .save()
        .then(() => {
          this.$emit("updated", {
            data: this.form,
            inboundPhoneNumber: this.inboundPhoneNumber,
          });
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Inbound Phone Number Updated",
            life: 3000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Error Saving Inbound Phone Number",
            life: 3000,
          });
        })
        .then(() => {
          this.loading = false;
          this.showModal = false;
        });
    },
  },
};
</script>

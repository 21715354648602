<template>
  <TitleBar>Manage Inbound Phone Numbers</TitleBar>
  <div class="py-2 px-1">
    <div
      class="grid mt-0 mx-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
    >
      <table class="ml-auto">
        <tr>
          <td>
            <Button
              label="Create New Inbound Phone Number"
              @click="
                selectedInboundPhoneNumber = {};
                selectedInboundPhoneNumber.campaign_id =
                  this.appStore.currentCampaign.id;
                toggleModal();
              "
            />
          </td>
        </tr>
      </table>
      <DataTable
        class="col-12"
        :value="inboundPhoneNumbers"
        responsiveLayout="scroll"
        :lazy="false"
        :loading="loading"
        :paginator="true"
        :rows-per-page-options="rowSelectionOptions"
        v-model:rows="rowsToShow"
        :totalRecords="totalRecords"
      >
        <template #header>
          <div class="text-right flex flex-row-reverse"></div>
        </template>

        <Column
          field="campaign_id"
          header="Campaign Id"
          :sortable="true"
        ></Column>

        <Column
          field="mailing_id"
          header="Mailing Id"
          :sortable="true"
        ></Column>

        <Column field="phone_number" header="Phone #" :sortable="true"></Column>

        <Column field="label" header="Label" :sortable="true"></Column>

        <Column
          field="campaign_cycle_id"
          header="Campaign Cycle Id"
          :sortable="true"
        ></Column>

        <Column header="Edit">
          <template #body="slotProps">
            <Button
              label="Edit"
              @click="
                selectedInboundPhoneNumber = slotProps.data;
                toggleModal();
              "
            />
          </template>
        </Column>
        <Column header="Delete">
          <template #body="slotProps">
            <Button
              label="Delete"
              @click="
                selectedInboundPhoneNumber = slotProps.data;
                confirmDelete();
              "
            />
          </template>
        </Column>
      </DataTable>
      <table class="ml-auto">
        <tr>
          <td>
            <Button
              label="Create New Inbound Phone Number"
              @click="
                selectedInboundPhoneNumber = {};
                selectedInboundPhoneNumber.campaign_id =
                  this.appStore.currentCampaign.id;
                toggleModal();
              "
            />
          </td>
        </tr>
      </table>
    </div>
  </div>

  <EditInboundPhoneNumber
    :visible="modalVisible"
    :inboundPhoneNumber="selectedInboundPhoneNumber"
    @updated="onUpdate"
  />
</template>

<script>
import InboundPhoneNumber from "@/models/InboundPhoneNumber";
import EditInboundPhoneNumber from "@/components/modals/EditInboundPhoneNumber";

import EventBus from "@/EventBus.js";
import TitleBar from "@/components/TitleBar.vue";
import { useAppStore } from "@/stores/app";

export default {
  name: "ManageInboundPhoneNumbers",
  components: { EditInboundPhoneNumber, TitleBar },
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      inboundPhoneNumbers: [],
      loading: false,

      selectedInboundPhoneNumber: null,
      modalVisible: false,

      totalRecords: 0,
      rowSelectionOptions: [10, 20, 50],
      rowsToShow: 10,
    };
  },
  mounted() {
    this.loadInboundPhoneNumbers();
  },
  methods: {
    loadInboundPhoneNumbers() {
      this.loading = true;

      InboundPhoneNumber.where("campaign_id", this.appStore.currentCampaign.id)
        // .where("mailing_id", "mailing")
        .get()
        .then((response) => {
          this.inboundPhoneNumbers = response.data;
          this.totalRecords = response.meta.total;
        })
        .catch()
        .then(() => (this.loading = false));
    },
    toggleModal() {
      EventBus.emit("toggle-client-modal");
    },
    confirmDelete() {
      if (
        confirm("Are you sure you want to delete this Inbound Phone Number?") ==
        true
      ) {
        let inboundPhoneNumber = new InboundPhoneNumber({});
        inboundPhoneNumber.id = this.selectedInboundPhoneNumber.id;

        inboundPhoneNumber
          .delete()
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Inbound Phone Number Deleted",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Error Deleting Inbound Phone Number",
              life: 3000,
            });
          })
          .then(() => {
            this.loadInboundPhoneNumbers();
          });
      }
    },
    onUpdate(value) {
      console.log(value);
      this.loadInboundPhoneNumbers();
    },
  },
};
</script>
